import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./features/login/login.component').then(c => c.LoginComponent)
  },
  {
    path: 'home',
    loadComponent: () => import('./features/home/page/home.component').then(c => c.HomeComponent),
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./features/home/components/trip-table/page/trip-table.component').then(c => c.TableLogisticComponent)
      },
      {
        path: 'new-trip',
        loadComponent: () => import('./features/home/components/trip-table/components/new-trip/new-trip.component').then(c => c.NewTripComponent)
      },
      {
        path: 'edit-trip/:tripUuid',
        loadComponent: () => import('./features/home/components/trip-table/components/new-trip/new-trip.component').then(c => c.NewTripComponent)
      },
      {
        path: 'new-delivery/:tripUuid',
        loadComponent: () => import('./features/home/components/trip-table/components/delivery-table/components/new-delivery/new-delivery.component').then(c => c.NewDeliveryComponent)
      },
      {
        path: 'edit-delivery/:tripUuid/:deliveryUuid',
        loadComponent: () => import('./features/home/components/trip-table/components/delivery-table/components/new-delivery/new-delivery.component').then(c => c.NewDeliveryComponent)
      },
      {
        path: 'new-user',
        loadComponent: () => import('./features/home/components/profile/components/create-user/create-user.component').then(c => c.CreateUserComponent)
      },
      {
        path: 'logs',
        loadComponent: () => import('./features/home/components/trip-table/components/logs/page/logs.component').then(c => c.LogsComponent)
      },
      {
        path: 'user',
        loadComponent: () => import('./features/home/components/user-crud/page/user-crud.component').then(c => c.UserCrudComponent)
      },
      {
        path: 'driver',
        loadComponent: () => import('./features/home/components/driver-crud/page/driver-crud.component').then(c => c.DriverCrudComponent)
      },
      {
        path: 'vehicle',
        loadComponent: () => import('./features/home/components/vehicle-crud/page/vehicle-crud.component').then(c => c.VehicleCrudComponent)
      },
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./shared/components/notFound/notFound.component').then(c => c.NotFoundComponent)
  },
  {
    path: '**',
     redirectTo: '/404'
  },
];
